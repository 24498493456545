<template>
  <div class="pcaap-container">
    <div>
      <el-row class="interval">
        <el-col :span="24">
          <el-button type="primary" @click="handleAddProductClick">{{ $t('form.coupon.createProduct') }}</el-button>
        </el-col>
      </el-row>
      <el-row class="interval">
        <el-col>
          <el-button type="primary" :disabled="mainTable.multipleSelection.length < 1" @click="handleBatchDestroyClick(-1)">{{
            $t('text.batchDestroy')
          }}</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-table
            ref="mainTable"
            :data="mainTable.data"
            v-loading="mainTable.loading"
            style="width: 100%"
            max-height="700"
            @selection-change="handleMainTableSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column :label="$t('form.activity.productName')" width="320" prop="name" />
            <el-table-column :label="$t('form.activity.shop')" prop="shop" />
            <el-table-column :label="$t('form.activity.supplier')" prop="supplier" />
            <el-table-column :label="$t('table.common.operate')" fixed="right" width="220">
              <template slot-scope="scope">
                <el-button class="margintop10" size="mini" type="danger" @click="() => handleBatchDestroyClick(scope.row.id)">{{ $t('button.delete') }}</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleMainTableSizeChange"
            @current-change="handleMainTableCurrentChange"
            :page-sizes="[50, 100, 150, 200]"
            :page-size="mainTable.pagination.papeSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="mainTable.pagination.totalCount"
          >
          </el-pagination>
        </el-col>
      </el-row>
      <el-row class="interval">
        <el-col :span="24">
          <el-button type="primary" :disabled="activeStep <= 0" @click="handleMainOkClick">{{ $t('button.confirm') }}</el-button>
        </el-col>
      </el-row>
    </div>
    <s-product-list-dialog
      :visible="dialog.productList.visible"
      :setVisible="setProductListDialogVisible"
      :activity="routeParams"
      :shopList="shopList"
      :supplierList="supplierList"
      :shopCategorieList="shopCategorieList"
      :shopSubCategorieList="shopSubCategorieList"
      @onSubmitClick="handleProductListDialogSubmitClick"
    />
  </div>
</template>

<script>
import loading from '@/utils/loading.json'
import dataDictionary from '@/utils/data-dictionary.js'
import SProductListDialog from './components/productListDialog/index.vue'
import * as promotionApi from '@/services/promotion.js'
import * as suppliersApi from '@/services/suppliers.js'
import * as shopCategoriesApi from '@/services/shop-categories.js'
import * as shopSubCategoriesApi from '@/services/shop-sub-categories.js'
import * as couponApi from '@/services/coupon.js'

export default {
  components: {
    SProductListDialog
  },
  props: ['activeStep', 'setActiveStep'],
  data () {
    return {
      dataDictionary,
      routeParams: null,
      activityDetail: null,
      shopList: [],
      supplierList: [],
      shopCategorieList: [],
      shopSubCategorieList: [],
      selectedProductId: null,
      mainTable: {
        loading: false,
        // 分页
        pagination: {
          papeSize: 50,
          current: 1,
          totalCount: 0
        },
        multipleSelection: [],
        // 数据
        data: []
      },
      mainForm: {
        data: {
          online: false, // 上架
          homepage_display: false, // 是否首页展示
          name: '', // 活动动名
          name_en: '', // 活动名(英文)
          date: null,
          begin_date: null, // 开始时间
          end_date: null, // 结束时间
          remote_image_url: null
        }
      },
      dialog: {
        productList: {
          visible: false
        },
        productInfo: {
          type: 0, // 0 新增 1 编辑
          visible: false,
          data: null,
          stock: []
        }
      }
    }
  },
  created () {
    const _this = this
    _this.routeParams = _this.$route.query
  },
  mounted () {
    const _this = this
    const initRequest1 = promotionApi.getShopsSimpleList().then(res => {
      _this.shopList = res.data.shops
    }).catch(err => err)
    const initRequest2 = suppliersApi.getSimpleList().then(res => {
      _this.supplierList = res.data.suppliers
    }).catch(err => err)
    const initRequest3 = shopCategoriesApi.getSimpleList().then(res => {
      _this.shopCategorieList = res.data.shop_categories
    }).catch(err => err)
    const initRequest4 = shopSubCategoriesApi.getSimpleList().then(res => {
      _this.shopSubCategorieList = res.data.shop_sub_categories
    }).catch(err => err)
    const initRequest5 = _this.requestMainTableData()
    _this.setGlobalLoading(true)
    Promise.all([initRequest1, initRequest2, initRequest3, initRequest4, initRequest5]).then(() => _this.setGlobalLoading(false)).catch(() => _this.setGlobalLoading(false))
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    setMainTableLoading (show) {
      const _this = this
      show ? _this.mainTable.loading = true : _this.mainTable.loading = false
    },
    setProductInfoDialogVisible (show) {
      const _this = this
      _this.dialog.productInfo.visible = show
    },
    setProductListDialogVisible (show) {
      const _this = this
      _this.dialog.productList.visible = show
    },
    requestMainTableData () {
      const _this = this
      _this.setMainTableLoading(true)
      return couponApi.getOnlineCouponProduct(_this.routeParams.id, {
        page: _this.mainTable.pagination.current,
        per_page: _this.mainTable.pagination.papeSize
      }).then(res => {
        _this.setMainTableLoading(false)
        const resData = res.data
        _this.mainTable.data = []
        _this.mainTable.pagination.totalCount = resData.pagination.total_count
        _this.$nextTick(() => {
          _this.mainTable.data = resData.objects
        })
      }).catch(() => _this.setMainTableLoading(false))
    },
    handleProductListDialogSubmitClick (selectedProductId) {
      const _this = this
      _this.dialog.productInfo.type = 0
      _this.selectedProductId = selectedProductId
      couponApi.onlineCouponProductAdd(this.routeParams.id, { poi_ids: selectedProductId }).then(res => {
        _this.setGlobalLoading(false)
        _this.dialog.productInfo.stock = res.data.shop_item_stocks
        _this.setProductListDialogVisible(false)
        _this.requestMainTableData()
      }).catch(() => _this.setGlobalLoading(false))
    },
    handleAddProductClick () {
      const _this = this
      _this.dialog.productList.visible = true
    },
    handleAddProductSuccess () {
      const _this = this
      _this.setActiveStep(2)
      _this.selectedProductId = null
      _this.setProductInfoDialogVisible(false)
      _this.mainTable.pagination.current = 1
      _this.mainTable.pagination.totalCount = 0
      _this.requestMainTableData()
    },
    handleAddProductCancel () {
      const _this = this
      _this.selectedProductId = null
    },
    handleMainTableSizeChange (val) {
      const _this = this
      _this.mainTable.pagination.current = 1
      _this.mainTable.pagination.totalCount = 0
      _this.mainTable.pagination.papeSize = val
      _this.requestMainTableData()
    },
    handleMainTableCurrentChange (val) {
      const _this = this
      _this.mainTable.pagination.current = val
      _this.requestMainTableData()
    },
    handleMainOkClick () {
      const _this = this
      _this.$router.replace({
        name: 'couponManagerOnlineCouponList'
      })
    },
    handleMainTableOnlineSwitchClick (data) {
      const _this = this
      _this.setGlobalLoading(true)
      promotionApi.putShopActivitiesIdItemsId(_this.activityDetail.id, data.id, {
        online: !data.online
      }).then(res => {
        _this.setGlobalLoading(false)
        _this.$message({
          type: 'success',
          message: _this.$t('message.operateSuccess')
        })
      }).catch(() => _this.setGlobalLoading(false))
    },
    handleMainTableSelectionChange (val) {
      const _this = this
      _this.mainTable.multipleSelection = val
    },
    handleBatchDestroyClick (data = -1) {
      const _this = this
      const params = { poi_ids: [] }
      let tip = _this.$t('text.confirmBatchDestroy')
      if (data === -1) {
        params.poi_ids = _this.mainTable.multipleSelection.map(item => item.id)
      } else {
        params.poi_ids.push(data)
        tip = _this.$t('text.confirmDeleteThisItem')
      }
      _this.$confirm(tip, _this.$t('text.confirmation'), {
        type: 'warning'
      }).then(() => {
        _this.setGlobalLoading(true)
        couponApi.onlineCouponProductDelete(_this.routeParams.id, params).then(() => {
          _this.setGlobalLoading(false)
          _this.$message({
            type: 'success',
            message: _this.$t('message.operateSuccess')
          })
          _this.requestMainTableData()
        }).catch(() => {
          _this.setGlobalLoading(false)
        })
      }).catch(err => err)
    }
  }
}
</script>

<style lang="scss" scoped>
.pcaap-container {
  .interval {
    margin-bottom: 12px;
  }
  .product-box {
    display: flex;
    align-items: center;
    .cover {
      flex-shrink: 0;
      width: 40%;
      margin-right: 12px;
      .img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .info {
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      .title {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: bold;
        word-break: break-all;
      }
    }
  }
}
</style>
